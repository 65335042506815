

















































































import { Component, Vue, Prop, Watch } from "vue-property-decorator"
import Messages from "~/collections/Messages"
import Message from "~/models/Message"
import House from "~/models/House"
import { state } from "@/store"
import Conversation from "~/models/Conversation"
import HouseCard from "@/components/house/HouseCard/HouseCard.vue"
import MessageCard from "@/components/messaging/MessageCard.vue"
import Verification from "~/models/Verification"

@Component({
  components: {
    MessageCard,
    HouseCard,
  },
})
export default class Messaging extends Vue {
  @Prop({ default: false }) displayConversation!: boolean | ""
  @Prop({ required: true }) conversation!: Conversation
  @Prop({ required: false }) house!: House
  messages: Message[] = []
  messageFetcher = 0
  fetchInterval = 5000 // milliseconds
  loadingMessages = true
  firstLoad = true

  mounted() {
    this.computeHeight()
    addEventListener("resize", this.computeHeight, { passive: true })
    this.loadMessages()
    //this.startMessageFetcher()
  }
  destroyed() {
    //clearInterval(this.messageFetcher)
    removeEventListener("resize", this.computeHeight)
  }
  // stopMessageFetcher() {
  //   this.messageFetcher && clearInterval(this.messageFetcher)
  // }
  // startMessageFetcher() {
  //   this.loadMessages()
  //   this.messageFetcher = setInterval(this.loadMessages, this.fetchInterval)
  // }

  @Watch("$route.params.dealId")
  @Watch("$route.params.houseId")
  async loadMessages() {
    this.loadingMessages = true
    const deal_id = state.currentDealId()
    if (!this.conversation || !deal_id) return
    const { houseId } = this.$route.params
    if (houseId && !this.conversation.house_id) return
    if (
      houseId &&
      (parseInt(houseId) != this.conversation.house_id ||
        deal_id != this.conversation.deal_id)
    ) {
      await this.loadConversation(deal_id, parseInt(houseId))
      return
    }
    const messages = new Messages()
    if (this.conversation?.conversable_type == "SelectedHouse")
      messages.assign({
        deal_id,
        house_id: this.conversation?.conversableObject.id,
      })
    else messages.assign({ deal_id, house_id: null })
    await messages.fetch()
    this.loadingMessages = false
    this.messages = messages.items
    if (this.firstLoad) {
      this.scrollToBottom()
      this.conversation.unseen_messages_count = 0
      await state.notifications.fetch()
      this.firstLoad = false
    }
  }

  async loadConversation(dealId: number, houseId: number) {
    this.conversation.deal_id = dealId
    this.conversation.house_id = houseId
    await this.conversation.fetch()
  }

  @Watch("conversation.id")
  changeConversation() {
    this.firstLoad = true
    this.loadMessages()
  }

  messageDeleted() {
    this.loadMessages()
  }

  get messagingFixed() {
    const { houseId } = this.$route.params
    return houseId ? "" : "messaging-fixed"
  }

  get messagingElement() {
    // @ts-ignore
    return this.$refs.messagingElement as HTMLElement
  }
  get messagingFixedElement() {
    // @ts-ignore
    return this.$refs.messagingFixedElement as HTMLElement
  }

  appendHouseSummary() {
    if (this.house) {
      state.currentMessage += Verification.generateVerificationMessage(
        this.house
      )
    }
  }

  computeHeight() {
    const top = Math.round(
      this.messagingElement.getBoundingClientRect().top + window.scrollY
    )
    const height = window.innerHeight - top
    this.messagingFixedElement.style.top = `${top}px`
    this.messagingFixedElement.style.height = `${height - 27}px`
    this.messagingFixedElement.style.width = `${this.messagingElement.clientWidth}px`
  }

  keypress(event: KeyboardEvent) {
    if (event.key != "Enter") return
    if (navigator.appVersion.includes("Mac")) {
      if (event.metaKey) this.submit()
    } else if (event.altKey) this.submit()
  }
  async submit() {
    const deal_id = state.currentDealId()
    state.currentMessage = state.currentMessage.trim()
    if (!this.conversation || !deal_id || !state.currentMessage) return

    //this.stopMessageFetcher()
    const message = new Message({
      deal_id,
      house_id:
        this.conversation?.conversable_type == "SelectedHouse"
          ? this.conversation?.conversableObject.id
          : null,
      body: state.currentMessage,
      created_at: new Date(),
      creator: state.profile,
      creator_id: state.profile.id,
    })
    this.messages.push(message)
    this.scrollToBottom()
    state.currentMessage = ""
    await message.saveAndAssign()
    //this.startMessageFetcher()
  }
  scrollToBottom() {
    setTimeout(() => {
      // @ts-ignore
      const element = this.$refs.messagesCard?.$el as HTMLElement
      if (element) element.scrollTop = element.scrollHeight
    }, 0)
  }
  openFullHouseCard(house: House) {
    this.$emit("openFullHouseCard", house)
  }
}
