import Collection from "~/jrdm-mc/Collection"
import Message from "~/models/Message"

export default class Messages extends Collection<Message> {
  model() {
    return Message
  }
  static responseField = "messages"
  static route = "deals/{deal_id}/houses/{house_id}/messages"

  deal_id = 0
  house_id = 0

  getFetchRoute() {
    return this.house_id ? Message.route : "deals/{deal_id}/messages"
  }

  constructor(object?: Record<string, any>) {
    super()
    object && this.assign(object)
  }
}
