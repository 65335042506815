import Model from "~/jrdm-mc/Model"
import Message from "~/models/Message"
import House from "~/models/House"
import Deal from "~/models/Deal"
import { toDate, model } from "~/utils/functionsFilter"

type Conversable = ({ type: "house" } & House) | ({ type: "deal" } & Deal)

export default class Conversation extends Model {
  static route = "deals/{deal_id}/houses/{house_id}/conversations"
  static responseField = "conversation"

  id: null | number = null
  deal_id: null | number = null
  house_id: null | number = null
  conversable_type: null | string = null
  conversable_id: null | number = null
  archived: null | boolean = null
  messages_count: null | number = null
  created_at: null | Date = null
  updated_at: null | Date = null
  unseen_messages_count: null | number = null
  conversable: null | Conversable = null
  last_message: null | Message = null

  static mutations = {
    created_at: toDate,
    updated_at: toDate,
    last_message: model(Message),
  }

  get conversableObject() {
    if (this.conversable_type == "SelectedHouse")
      return new House().assign(this.conversable)
    else this.conversable_type == "Deal"
    return new Deal().assign(this.conversable)
  }
}
