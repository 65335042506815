

























































import { Component, Vue, Prop } from "vue-property-decorator"
import OcularAvatar from "~/components/OcularAvatar.vue"
import OcularPrompt from "~/components/OcularPrompt.vue"
import Message from "~/models/Message"
import OcularOptionsMenu from "~/components/OcularOptionsMenu.vue"
import { state } from "@/store"

@Component({
  components: {
    OcularOptionsMenu,
    OcularAvatar,
    OcularPrompt,
  },
})
export default class MessageCard extends Vue {
  @Prop({ required: true }) message!: Message
  editable = false
  isHover = false

  get isCurrentUser() {
    return this.message?.creator?.id == state.profile.id
  }
  get options() {
    return [
      {
        name: "Editer",
        icon: "Edit",
        action: () => {
          this.editable = true
          this.$emit("start-edition")
        },
      },
      {
        name: "Supprimer",
        icon: "Trash",
        action: this.openRemoveConfirmation,
      },
    ]
  }

  openRemoveConfirmation() {
    const dialog = this.$refs.deleteConfirmPrompt as OcularPrompt
    dialog.open = true
  }
  removeMessage() {
    this.message.delete().then(() => {
      this.$emit("delete")
    })
  }
  messageUpdated() {
    this.editable = false
    this.message.save().then(() => {
      this.$emit("stop-edition")
    })
  }
}
