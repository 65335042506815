
































import { Component, Vue, Prop } from "vue-property-decorator"
import OcularAvatar from "~/components/OcularAvatar.vue"

@Component({
  components: {
    OcularAvatar,
  },
})
export default class OcularOptionsMenu extends Vue {
  @Prop({ required: false, default: 18 }) size!: number
  @Prop({ required: false }) iconClass!: string
  @Prop({ required: true }) options!: Array<{
    name: string
    icon: string
    action: () => void
  }>
  @Prop({ required: false }) attach!: string
}
